var defaultConf = require('./config-default');

var NODE_BACKEND_DOMAIN = '';
var NODE_WS_BAKEND_DOMAIN = '';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: 'europain-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_PATH: '/home/www/europain-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '~/europain-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-19',
  projectId: '470458471086',
  // FCM sender id
  appId: '2E98B-956DD',
  // pushwoosh appid
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  }
});