
// Libs
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

// Conf
import { DATA_TYPE_SERVICES } from 'data/config/dataConfig';

import { SERVICE_PAGE_KEY } from 'src/pages/pagesKeys';

// App modules
import AppToolbar     from 'src/components/app-toolbar/AppToolbar';
import Menu           from 'src/components/menu/Menu';
import FetchResult    from 'src/components/fetch-result/FetchResult';
import ServiceContent from './ServiceContent';
import * as Notes from 'src/core/notes/Notes';
import * as actions from 'src/store/actions';

import '../common-fiche.scss';
import './ServicePage.scss';


const LOG_PREF = '[ServicePage] ';


function getPageTitle(item, labels) {
    let serviceName = item ? item.title : '';

    return labels.data[DATA_TYPE_SERVICES].singular + labels.common.colon + serviceName;
}

function getToolbarTitle(item, labels) {
    if (item) {
        return item.lump.page_title || labels.data[DATA_TYPE_SERVICES].singular
    }
    return labels.data[DATA_TYPE_SERVICES].singular;
}


class ServicePage extends Component {

    constructor(props) {
        super(props);

        this.pageKey = SERVICE_PAGE_KEY;
        this.serviceId = props.id;

        this.state = {};
    }

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(getPageTitle(this.props.item, this.props.labels));
        }
    }

    componentDidMount() {
        this.fetchIfNeeded(this.props);
        this.props.actions.fetchFavorites();
    }

    componentWillReceiveProps(nextProps) {
        this.fetchIfNeeded(nextProps);

        // Auto show POI on map if:
        //  - two columns mode is enabled
        //  - for this page, configuration indicates to automatically show the POI
        //  - item has been fetched
        //  - no item had been fetched yet or `id` is different
        if (this.props.twocolumns === true
                && this.props.autoShowPoi
                && nextProps.item
                && (!this.props.item || nextProps.item.id !== this.props.item.id)) {

            this.props.actions.showOnePoiOnMap({
                id        : nextProps.item.id,
                originalId: nextProps.item.original_id,
                type: DATA_TYPE_SERVICES,
            });
        }
    }

    componentDidUpdate() {
        if (this.idHasChanged === true) {
            this.idHasChanged = false;

            // child ref not available on componentDidUpdate
            window.setTimeout(this.resetScroll, 100, this);
        }
        this.setDocumentContext();
    }

    resetScroll(_this) {
        let context = _this || this;
        if (context.contentComponent && context.contentComponent.scroller) {
            context.contentComponent.scroller.scrollTo(0);
        }
    }

    /**
     * Fetch data if :
     *  - data has been updated (shouldFetch: true)
     *  - or id is different
     *  - or if we have no service data and request is neither pending nor without result
     */
    fetchIfNeeded(props) {
        const hasFetched = props.item || props.isPending === true || props.itemNotFound === true;

        if (props.id !== this.serviceId) {
            this.idHasChanged = true;
        }
        if (
            props.shouldFetch === true ||
            this.idHasChanged ||
            !hasFetched
        ) {
            this.serviceId = props.id;
            props.actions.fetchItem(props.id, DATA_TYPE_SERVICES, [ 'places' ]);
        }
    }

    render() {
        console.log(LOG_PREF+'render');

        // Avoid resetting contextual search field
        if (!this.props.isVisible && !this.props.searchResults) {
            return null;
        }

        const hasNote = (Notes.getNote(this.props.id,DATA_TYPE_SERVICES) && Notes.getNote(this.props.id,DATA_TYPE_SERVICES) !== "")==true

        return (
            <Menu
                options={{ isOpen: this.props.isMenuOpen }}
                actions={this.props.actions}
                labels={this.props.labels}
                profile={this.props.profile}
                associatedPageKey={this.pageKey}
                adConfig={this.props.adConfig}
                twoColumns={this.props.twocolumns}
                isLocationEnabled={this.props.isLocationEnabled}>

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={getToolbarTitle(this.props.item, this.props.labels)}
                    contextualDataTypes={[DATA_TYPE_SERVICES]}
                    onSearchClear={this.props.actions.clearSearchResults}
                    searchResults={this.props.searchResults}
                    pageKey={this.pageKey}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}>

                    <FetchResult
                        contentRef={ref => { this.contentComponent = ref; }}
                        Component={ServiceContent}
                        isPending={this.props.isPending}
                        itemNotFound={this.props.itemNotFound}
                        // Content props
                        item={this.props.item}
                        isFavorite={this.props.isFavorite}
                        isLogoFullWidth={this.props.isLogoFullWidth}
                        searchResults={this.props.searchResults}
                        favorites={ this.props.favorites }
                        hasNote={hasNote}
                        favIconDisabled={ this.props.favIconDisabled }
                        // Common
                        labels={this.props.labels}
                        actions={this.props.actions}
                        profile={this.props.profile}
                        queryString={this.props.queryString}
                        getPageTitle={getPageTitle} />

                </AppToolbar>
            </Menu>
        );
    }
};


ServicePage.propTypes = {
    id          : PropTypes.number.isRequired,
    item        : PropTypes.object,
    isFavorite  : PropTypes.bool,
    isPending   : PropTypes.bool,
    itemNotFound: PropTypes.bool,
    // Common page props
    autoShowPoi       : PropTypes.bool,
    isMenuOpen        : PropTypes.bool.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    queryString       : PropTypes.string.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLogoFullWidth   : PropTypes.bool,
    isLocationEnabled : PropTypes.bool,
    searchResults     : PropTypes.object,
    // toolbar
    hasToolbar           : PropTypes.bool,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[SERVICE_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServicePage);
