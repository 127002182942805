import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_SERVICE_CATEGORIES } from 'data/config/dataConfig'; // app modules

import { LIST_PAGE_KEY, MAP_PAGE_KEY, MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys';
import Banner from 'src/components/banner/Banner';
import AdSwap from 'src/components/ad-swap/AdSwap';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import { AD_KEYS } from 'src/core/config-json/ConfigJsonManager';
var LOG_PREF = '[homeConfig] ';

var _require = require('./profiles'),
    DEFAULT_PROFILE = _require.DEFAULT_PROFILE; // NB: used for ChooseProfile page too !


export var getStyle = function getStyle() {
  return {
    backgroundImage: 'url(' + getUrl('files/project/home/home_background.jpg') + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: 'white'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 2
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 220
};
export var GENERAL_TILE_OPTIONS = {
  height: 0.65,
  // rowspan
  width: 1 // colspan

}; // TEMP FIXME (use jsontosass-loader ?)

var color1 = "#005EA7";
var color2 = "#122D62";
var tileBgGradient = 'linear-gradient(rgba(17, 17, 17, 0.65), rgba(85, 85, 85, 0.65))';

var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem( // Style
  {
    container: {
      style: {},
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")")
      } //className: 'home-icon-plan',

    }
  }, // Label (i18n)
  homebutton['label_' + lang], // Action to perform on click
  function (actions) {
    var url = homebutton['link_' + lang];

    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getDefault = function getDefault(lang, adConfig, orientation) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adKey: AD_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {// Black list of buttons just to avoid side effect on older projects
      // disabledButtons : ["inbox"]
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [// Exhibitors
      createItemRoute( // Style
      {
        container: {
          // style: {
          //     backgroundImage: tileBgGradient,
          // },
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/exposants.svg') + ')'
          } //                                className: 'home-icon-exposants',

        }
      }, // Label (i18n)
      'home.exhibitors', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: "",
        inputs: [{
          dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
        }]
      }, // Tile options
      GENERAL_TILE_OPTIONS), // NOUVEAUTÉS
      createItemRoute( // Style
      {
        container: {
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/nouveautés.svg') + ')'
          }
        }
      }, // Label (i18n)
      'home.newproducts', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: "",
        inputs: [{
          dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
        }]
      }, // Tile options
      GENERAL_TILE_OPTIONS), // Programme
      createItemRoute( // Style
      {
        container: {
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/agenda.svg') + ')'
          }
        }
      }, // Label (i18n)
      'home.agenda', // Page & props
      LIST_PAGE_KEY, {
        inputs: [{
          dataType: DATA_TYPE_EVENT_CATEGORIES
        }],
        hasGoToSynopticButton: false
      }, // Tile options
      GENERAL_TILE_OPTIONS), // INFOS PRATIQUES
      createItemRoute( // Style
      {
        container: {
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/infos-pratiques.svg') + ')'
          }
        }
      }, // Label (i18n)
      'home.practicalInfo', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: "",
        inputs: [{
          dataType: DATA_TYPE_SERVICE_CATEGORIES
        }]
      }, // Tile options
      GENERAL_TILE_OPTIONS), // PLAN
      createItemRoute( // Style
      {
        container: {
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/plan.svg') + ')'
          }
        }
      }, // Label (i18n)
      'home.map', // Page & props
      MAP_PAGE_KEY, null, // Tile options
      GENERAL_TILE_OPTIONS), // Social Media
      // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
      createItemRoute( // Style
      {
        container: {
          style: {},
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/news.svg') + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 0%'
          }
        }
      }, // Label (i18n)
      'home.medias', // Page & props
      MEDIAS_PAGE_KEY, null, // Tile options
      GENERAL_TILE_OPTIONS)]
    }
  }, {
    component: AdSwap,
    props: {
      adKey: AD_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @return {object}
 */
// export const get = (profile, lang, adConfig, orientation) => {
//     return getDefault(lang, adConfig);
// };


export var get = function get(profile, lang, adConfig, orientation) {
  return getDefault(lang, adConfig, orientation);
};