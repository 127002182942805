
import { getSortedAndTransformedData } from 'src/core/data-and-assets/Db';
import { getParentCategoriesId } from 'src/core/query/Query';


/**
 * such as
 *   itemId1: [ all cats ids ],
 *   itemId2: [ all cats ids ],
 *   etc
 * @type {Object}
 */
let itemsCats = {};

function initItem(itemId) {
    if (!itemsCats[itemId]) {
        itemsCats[itemId] = [];
    }
}

const TIME_CODE = 'GET ALL CATEGORIES PER ITEM';

export const generate = (itemDataType, catDataType) => {
    console.time(TIME_CODE);

    let data = getSortedAndTransformedData();

    data[catDataType].forEach(cat => {
        if (cat.is_all) {
            return;
        }
        if (cat.lump && cat.lump[itemDataType]) {
            let hierarchyCatIds = [ cat.id, ...getParentCategoriesId(cat, catDataType) ]

            cat.lump[itemDataType].forEach(itemId => {
                initItem(itemId);
                itemsCats[itemId] = itemsCats[itemId].concat(hierarchyCatIds);
            });
        }
    });

    // Remove duplicates
    Object.keys(itemsCats).forEach(itemId => {
        itemsCats[itemId] = [...new Set(itemsCats[itemId])];
    });

    console.timeEnd(TIME_CODE);
    //console.log(itemDataType+' all categories', itemsCats);
}

global.generate = generate;

export const getCategoriesOf = itemId => itemsCats[itemId];
