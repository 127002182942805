
import React from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es.js';

import './Tabs.scss';

const TAB_MIN_WIDTH = 120;
const TAB_MAX_WIDTH = 200;

const FOLLOWING_TAB_WIDTH_VISIBLE = 25; // percent

function getCountDependingOnWidth(clientWidth) {
    if (clientWidth < 800) {
        return 3;
    }
    if (clientWidth < 1280) {
        return 4;
    }
    return 5;
}


export default class Tabs extends React.Component {

    static propTypes = {
        data: PropTypes.array.isRequired,
        onTabIndexChange: PropTypes.func.isRequired,
        style: PropTypes.object,
        selectedTabStyle: PropTypes.object,
    }

    static defaultProps = {
        style: {},
        selectedTabStyle: {},
    }

    setTabWidth() {
        let totalTabs = Object.keys(this.props.data).length;

        let visibleTabCount = Math.min(
            totalTabs,
            getCountDependingOnWidth(document.documentElement.clientWidth)
        );

        let percentOfTheNextTab = 0;
        if (totalTabs > visibleTabCount) {
            percentOfTheNextTab = FOLLOWING_TAB_WIDTH_VISIBLE/100;
        }

        let width = document.documentElement.clientWidth / (visibleTabCount+percentOfTheNextTab);

        if (width < TAB_MIN_WIDTH) {
            this.tabWidth = TAB_MIN_WIDTH;
        }
        else if (width > TAB_MAX_WIDTH) {
            this.tabWidth = TAB_MAX_WIDTH;
        }
        else {
            this.tabWidth = width;
        }
    }

    setTabsRef = el => {
        this.tabsEl = el;

        if (this.tabsEl) {
            if (this.tabsEl.offsetWidth < document.documentElement.clientWidth) {
                // No auto scroll when there is no x overflow
                this.xMaxThreshold = 0;
            } else {
                this.xMaxThreshold = this.tabsEl.offsetWidth - document.documentElement.clientWidth;
            }
        }
    }

    setTabsContainerRef = el => {
        this.tabsContainerEl = el;
    }

    handleTabClick = event => {
        let tabEl = event.target,
            tabIndex = parseInt(tabEl.dataset.index, 10);

        this.props.onTabIndexChange(tabIndex);

        // Auto scroll to center tab el
        if (this.tabsEl && this.tabsContainerEl) {
            let newLeftValue = tabEl.offsetLeft - (document.documentElement.clientWidth-tabEl.offsetWidth)/2;

            // keep most right element along the edge
            if (newLeftValue > this.xMaxThreshold) {
                newLeftValue = this.xMaxThreshold;
            }
            // keep most left element along the edge
            else if (newLeftValue < 0) {
                newLeftValue = 0;
            }
            anime({
                targets: this.tabsContainerEl,
                scrollLeft: newLeftValue,
                easing: 'easeInOutCirc',
                duration: 600,
            });
        }
    }

    tabRenderer = ({ key, getTabContent, isCurrentTab, ...remainingProps }, index) => (
        <div
            key={key}
            data-index={index}
            className={'t-tab'+(isCurrentTab() ? ' t-tab-selected' : '')}
            style={{ width: this.tabWidth+'px' }}
            onClick={this.handleTabClick}
            {...remainingProps}>{ getTabContent() }</div>
    )

    render() {
        if (!Array.isArray(this.props.data) || this.props.data.length === 0) {
            return null;
        }

        this.setTabWidth();

        return (
            <div ref={this.setTabsContainerRef}
                 className="t-tabs-container"
                 style={this.props.style}>

                <div ref={this.setTabsRef}
                     className="t-tabs"
                     style={{ width: this.props.data.length*this.tabWidth }}>

                    { this.props.data.map(this.tabRenderer) }
                </div>
            </div>
        );
    }
}