import React, { PureComponent, Component } from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "react-onsenui";

import config from "data/config/config";

import Button from "src/components/button";
import List from "src/components/list/List";
import TypeBar from "src/components/type-bar/TypeBar";
import NoResult from "src/components/no-result/NoResult";
import AllOnMapButton from "src/components/all-on-map-button/AllOnMapButton";
import { stripToIds } from "src/pages/map/mapUtil";

import { iosHackScrollTo0 } from "src/core/util/browser";

import "../../components-standalone/genericModal.scss";

import { MAP_TYPE } from "data/config/mapConfig";

import { NOTES_PAGE_KEY } from "src/pages/pagesKeys";

import ShareButton from "src/components/share-button/ShareButton";

import Pages, { PAGE_CSSCLASS } from "src/pages/Pages";
import { DATA_TYPE_TO_PAGE_KEY } from "src/pages/dataToPageMapping";

import { encodeProps } from "src/core/navigation/History";

import { addHttpIfNeeded } from "src/core/util/JsTools";

import { get } from "src/core//query/Query";

const DEFAULT_DIALOG_PROPS = {
    isCancelable: true,
    animation: "default", // or 'none', no other choice sadly
    className: "project-font"
};

class NotesContent extends Component {
    componentWillUpdate(nextProps) {}

    render() {
        let typedLists = [];
        let description = "";

        if (this.props.notes) {
            Object.keys(this.props.notes).forEach(dataType => {
                let items = this.props.data[dataType];

                // Ignore this dataType if it has no data
                if (
                    this.props.notes[dataType].length &&
                    items &&
                    items.length
                ) {
                    const count = items.length,
                        listProps = {
                            items: items,
                            dataType: dataType,
                            notes: this.props.notes,
                            notesStr: JSON.stringify(this.props.notes),
                            isPending: this.props.isPending,
                            actions: this.props.actions,
                            labels: this.props.labels,
                            pageKey: this.pageKey,
                            displayFavorites: false,
                            isNotesList: true
                        };
                    description += " \n \n" + dataType + " : \n \n";
                    let notes = this.props.notes.data[dataType];
                    notes.map((ele, index) => {
                       

                        let id = parseInt(this.props.notes[dataType][index], 10)

                        let item = get(
                            id,dataType
                        );
                        
                        let page = Pages[DATA_TYPE_TO_PAGE_KEY[dataType]];
                        let encodedOptions = encodeProps(
                            {
                                id: id
                            },
                            page.key
                        );

                        let queryString =
                            config.ROUTE_SEPARATOR + page.path + encodedOptions;

                        let url = addHttpIfNeeded(
                            config.WEB_URL + "/" + queryString,
                            true
                        );
                        description += item.title + " - "+ url +" \n";
                        description += "---- \n";
                        description += "\""+ele+"\"" + " \n \n";
                        
                    });
                    typedLists.push(
                        <TypeBar
                            key={dataType}
                            label={
                                this.props.labels.data[dataType][
                                    count > 1 ? "plural" : "singular"
                                ]
                            }
                            count={count}
                        >
                            <List {...listProps} />
                        </TypeBar>
                    );
                }
            });
        }

        return (
            <div
                className="favorites-content content-font"
                style={{ height: this.props.height }}
            >
                {typedLists.length === 0 ? (
                    <NoResult
                        labels={this.props.labels}
                        customLabel={this.props.labels.favorites.noResults}
                    />
                ) : (
                    <div>
                        <div className="favorites-options-container">
                            {MAP_TYPE !== "NOMAP" && (
                                <AllOnMapButton
                                    labels={this.props.labels}
                                    actions={this.props.actions}
                                    data={stripToIds(this.props.data)}
                                />
                            )}
                            <div className="notes-button-container">
                                <ShareButton
                                    name={this.props.labels.notes.pageTitle}
                                    queryString={""}
                                    description={description}
                                    image={""}
                                    labels={this.props.labels}
                                    actions={this.props.actions}
                                    profile={""}
                                    pageKey={NOTES_PAGE_KEY}
                                    noteExport={true}
                                />
                            </div>
                        </div>
                        <div>{typedLists}</div>
                    </div>
                )}
            </div>
        );
    }
}

NotesContent.propTypes = {
    data: PropTypes.object,
    notes: PropTypes.object,
    isPending: PropTypes.bool,
    isSynchroFavFeatureEnabled: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    // Common
    labels: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    profile: PropTypes.string,
    height: PropTypes.number // prop set from AppToolbar
};

export default NotesContent;
