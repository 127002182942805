module.exports = {
  name: 'EUROPAIN 2020',
  client: 'GL EVENTS',
  description: "Application du salon EUROPAIN 2020",
  pluginId: 'mobigeo-plugin-ush',
  version: '2.0.1',
  validLangs: ['fr', 'en'],
  native: {
    name: {},
    // by env
    version: '6',
    author: 'Mobile-Spot',
    id: '',
    // by env
    // 'ionic/www', 'simple', 'test', 'demo', 'app-react'
    app: 'app-react',
    backgroundColor: '0xffffff',
    // iOS
    iosAppStoreUrl: '',
    // by env
    iosBuildTarget: '10.0',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: '',
    // by env
    androidFadeSplashScreenDuration: 750,
    androidSigning: {
      keyAlias: 'mobile-spot',
      keyStorePassword: 'jee1Uu0Hieloh7bi'
    }
  },
  // List the folders of the data bundles to include for this project
  data: {
    default: 'oR9voo2k'
  },
  // 'location', 'location.geogroup', 'location.geopush', 'map.route', 'assets.update', 'poi.aroundme', 'poi.states'
  features: ['map.route'],
  location: {
    mapping: {},
    registering: {
      location: false,
      geofencing: false
    }
  },
  undeliveredFolders: ['source', 'exports'],
  cordova: true,
  crypto: false,
  customFileUrlScheme: 'msfile',
  cordova_plugins: {
    mobigeo_plugin_dependencies: [{
      id: 'cordova-plugin-file-transfer'
    }],
    app_dependencies: [{
      id: 'cordova-android-support-gradle-release'
    }, // Solve android support dependencies (versions conflict)
    {
      id: 'cordova-plugin-inappbrowser'
    }, {
      id: 'cordova-plugin-splashscreen'
    }, {
      id: 'cordova-plugin-x-socialsharing'
    }, {
      id: 'cordova-plugin-urloverride'
    }, {
      id: '../src-app-plugins/cordova-plugin-mobilespot-app',
      variable: 'HOST',
      varValue: 'web.url'
    }, {
      id: 'https://github.com/Mobile-Spot/pushwoosh-phonegap-plugin.git'
    }, {
      id: 'cordova-plugin-ionic-keyboard'
    }]
  },
  geoGroup: {},
  web: {}
};