
if (process.env.NODE_ENV === 'test') {
    global.browserDetect = require('../../../static/browserDetect');
}


export const isIOS = global.browserDetect.isIOS;

export const isIpad = global.browserDetect.isIpad;

export const getIosVersion = global.browserDetect.getIosVersion;

export const isWkWebview = global.browserDetect.isWkWebview;

export const isIphoneX = global.browserDetect.isIphoneX;

export const isAndroid = global.browserDetect.isAndroid;

export const isIE = global.browserDetect.isIE;

export const isEdgeBelow16 = global.browserDetect.isEdgeBelow16;

export const isFirefox = global.browserDetect.isFirefox;

export const isChrome = global.browserDetect.isChrome;

export const isCordovaContext = global.browserDetect.isCordovaContext;


export function addMeaningfulCssClass() {

    let el = document.body.parentNode;

    // Add some helpful css classes indicating on which environment the app is running on
    // Cordova / web
    el.classList.add(global.isCordovaContext ? 'is-cordova' : 'is-web');

    // iOS
    if (isIOS()) {
        el.classList.add('is-ios');
        if (isIphoneX()) {
            el.classList.add('is-iphonex');
        }
    } else {
        el.classList.add('not-ios');

        // Android
        if (isAndroid()) {
            el.classList.add('is-android');
        } else {
            el.classList.add('not-android');

            // IE
            if (isIE()) {
                el.classList.add('is-ms'); // Used for IE/Edge common fixes
                el.classList.add('is-ie');
            }
            // Edge
            else if (isEdgeBelow16()) {
                el.classList.add('is-ms'); // Used for IE/Edge common fixes
                el.classList.add('is-old-edge');
            }
        }
    }
}

/**
 * One more iOS hack
 * see: https://github.com/apache/cordova-ios/issues/417
 * and: https://openradar.appspot.com/radar?id=5018321736957952
 *
 * After keyboard has been displayed, events Y position is shifted
 */
export function iosHackScrollTo0() {
    if (global.isCordovaContext && isIOS()) {
        window.scrollTo(0,0);
    }
}
