module.exports = require('deepmerge')(require('./config.default.js'), {
  native: {
    name: {
      default: 'Europain-D'
    },
    id: 'com.mobilespot.europain.dev'
  },
  web: {
    url: 'europain-dev.mobile-spot.com'
  }
});